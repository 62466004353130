import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm, FormBuilder } from '@angular/forms';
import { AuthService } from '../service/auth-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';
  equalPassword: boolean = false;

  register = {
    'firsName': '',
    'lastName': '',
    'email': '',
    'password': '',
    'repassword': ''
  }

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {

    this.registerForm = new FormGroup({
      'firstName': new FormControl(this.register.firsName, [Validators.required, Validators.minLength(3)]),
      'lastName': new FormControl(this.register.lastName, [Validators.required, Validators.minLength(3)]),
      'email': new FormControl(this.register.email, [
        Validators.required,
        Validators.pattern("[^ @]*@[^ @]*")
      ]),
      'password': new FormControl(this.register.password, [
        Validators.minLength(8),
        Validators.required
      ]),
      'repassword': new FormControl(this.register.repassword, [
        Validators.minLength(8),
        Validators.required])
    });
  }

  get firstName() { return this.registerForm.get('firstName'); }
  get lastName() { return this.registerForm.get('lastName'); }
  get email() { return this.registerForm.get('email'); }
  get password() { return this.registerForm.get('password'); }
  get repassword() { return this.registerForm.get('repassword'); }

  postData(registerForm: NgForm) {

    console.log(registerForm.controls);

    console.log("form ", registerForm);

  }

  tryRegister() {

    console.log("form", this.registerForm);
    console.log("status", this.registerForm.status);
    let password = this.registerForm.get('password');
    let repassword = this.registerForm.get('repassword');

    if (this.registerForm.status.toLowerCase() == "valid" && password == repassword) {

      this.authService.doRegister(this.registerForm.value)
        .then(res => {

          console.log(res);
          this.errorMessage = "";
          this.successMessage = "Your account has been created";

        }, err => {

          console.log(err);
          this.errorMessage = err.message;
          this.successMessage = "";

        })

    } else {
      if (password != repassword)
        this.equalPassword = true;
    }




  }




}
