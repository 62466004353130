import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  tab = 'about';

  constructor(){
  
  }

  ngOnInit() {
    if(localStorage.getItem('tab'))
      this.tab = localStorage.getItem('tab');
  }

  changeTab(tabName){
    console.log('tabName ', tabName)
    this.tab = tabName;
    localStorage.setItem('tab', this.tab);
  }

}
