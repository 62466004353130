import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent implements OnInit {


  @Input() activeTab: number;

  get getActiveTab(): number {
    return this.activeTab;
  }

  set setActiveTab(active: number){

    this.activeTab = active;

  }
  ngOnInit() {
    console.info("header init");

  }

}
