import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl, NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;



  contact = {
    'name': 'adelso',
    'email': '',
    'phone': '',
    'to': 'adelsoneves9@gmail.com',
    'subject': 'contact from adelsomelo',
    'content': ''
  }

  ngOnInit() {
    console.log('contact component init');
    this.contactForm = new FormGroup({
      'name': new FormControl('', [Validators.required, Validators.minLength(3)]),
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern("[^ @]*@[^ @]*")
      ]),
      'phone': new FormControl('', [
        Validators.minLength(5),
        Validators.required
      ]),
      'content': new FormControl('', [
        Validators.minLength(8),
        Validators.required])
    });

  }

  ngOnChanges() {
    console.log("changes where done");
  }

  get name() { return this.contactForm.get('name'); }
  get email() { return this.contactForm.get('email'); }
  get phone() { return this.contactForm.get('phone'); }
  get content() { return this.contactForm.get('content'); }

  postData(contactForm: NgForm) {

    console.log(contactForm.controls);

    console.log("form ", contactForm);

   

  }


}
