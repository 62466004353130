import { Component, OnInit } from '@angular/core';
import { PostService } from '../service/post-service';
import { Observable } from 'rxjs';
import { Post } from '../model/post.model';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  posts: Post[];

  constructor(private postService: PostService) {

  }

  ngOnInit() {
    this.loadAllPosts();
  }

  loadAllPosts() {
    this.postService.getAll().subscribe(post => { 
      this.posts = post;
      console.log(post)
     });
  }
  dataLoader() {
    console.log("called dataLoader()");
  }

}
