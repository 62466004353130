// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBa_Pa8ZtncOFUSAts9PuYH1FH0QEnXwYk",
    authDomain: "adelsomelo-st.firebaseapp.com",
    databaseURL: "https://adelsomelo-st.firebaseio.com",
    projectId: "adelsomelo-st",
    storageBucket: "adelsomelo-st.appspot.com",
    messagingSenderId: "1012148250531"
  }

};