import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor() { }


  registerForm: FormGroup;

  register = {
    'firsName': '',
    'lastName': '',
    'email': '',
    'password': '',
    'repassword': ''
  }

  ngOnInit(): void {

    this.registerForm = new FormGroup({
      'email': new FormControl(this.register.email, [
        Validators.required,
        Validators.pattern("[^ @]*@[^ @]*")
      ]),
      'password': new FormControl(this.register.password, [
        Validators.minLength(8),
        Validators.required
      ]),
      'repassword': new FormControl(this.register.repassword, [
        Validators.minLength(8),
        Validators.required])
    });
  }

  get firstName() { return this.registerForm.get('firstName'); }
  get lastName() { return this.registerForm.get('lastName'); }
  get email() { return this.registerForm.get('email'); }
  get password() { return this.registerForm.get('password'); }
  get repassword() { return this.registerForm.get('repassword'); }

  postData(registerForm: NgForm) {

    console.log(registerForm.controls);

    console.log("form ", registerForm);

   

  }


}
