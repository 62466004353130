import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/index';
import { ContactComponent } from './contact/contact.component'
import { AboutComponent } from './about/about.component'
import { BlogComponent } from './blog/blog.component'
import { BlogPostComponent } from './blog-post/blog-post.component'
import { AccountComponent } from './account/account.component'
import { RegisterComponent } from './register/register.component'
import { LoginComponent } from './login/login.component';

const appRoutes: Routes = [
   { path: '', component: HomeComponent},
   { path: 'contact', component: ContactComponent },
   { path: 'about', component: AboutComponent },
   { path: 'account', component: AccountComponent },
   { 
       path: 'blog', 
       component: BlogComponent,
        children: [
            {
                path: 'id',
                component: BlogPostComponent
            }

        ] 
    },
   { path: 'register', component: RegisterComponent },
   { path: 'login', component: LoginComponent },

   // otherwise redirect to home
   { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);